(function () {
    "use strict";

    angular.module('informaApp')
        .directive('infHomeFilterSection',
            ['ModalHelper', 'DiseaseService', 'CompanyService', 'DrugClassesService',
                'MoleculeService', 'TargetService', 'DrugService', 'RouteService',
                'ConstantsSvc', 'CompanyModesEnum', HomeFilterSection]);

    function HomeFilterSection(ModalHelper, DiseaseService, CompanyService, DrugClassesService,
                               MoleculeService, TargetService, DrugService, RouteService, ConstantsSvc,
                               CompanyModesEnum) {
        return {
            restrict: 'E',
            templateUrl: 'directives/home/inf-home-filter-section/template.ptl.html',
            scope: {
                tooltips: '=',
                onFilterChange: '=',
                actions: '=',
                onFilterReady: '=',
                onRegulatoryChange: '=',
                onTimeframeChange: '=',
                onModalChange: '='
            },
            defaultCompanyMode: CompanyModesEnum.leadOnly,
            link: function (scope) {
                const this_ = this;

                this.setDefaultFilter(scope);

                scope.dateRangeOptions = this.getDateRangeOptions(scope);

                scope.showDiseases = getShowModalFunction('diseases');
                scope.showCompanies = getShowModalFunction('companies');
                scope.showDrugClasses = getShowModalFunction('drugClasses');
                scope.showMolecules = getShowModalFunction('molecules');
                scope.showTargets = getShowModalFunction('targets');
                scope.showDrugs = getShowModalFunction('drugs');
                scope.showRoutes = getShowModalFunction('routes');

                scope.onSelectDiseases = bindOnSelectItems('diseases');
                scope.onSelectCompanies = bindOnSelectItems('companies');
                scope.onSelectDrugClasses = bindOnSelectItems('drugClasses');
                scope.onSelectMolecules = bindOnSelectItems('molecules');
                scope.onSelectTargets = bindOnSelectItems('targets');
                scope.onSelectDrugs = bindOnSelectItems('drugs');
                scope.onSelectRoutes = bindOnSelectItems('routes');

                scope.onExcludeTargets = bindOnExcludeItems('targets');

                scope.$watch('regulatory', onRegulatoryChange, true);
                scope.$watch('timeframe', onTimeframeChange, true);

                scope.$watch('actions', function () {
                    if (scope.actions) {
                        scope.actions.resetFilter = resetFilter;
                        scope.actions.applyFilter = applyFilter;
                        scope.actions.getFilterWithNames = getFilterWithNames;

                        if (scope.onFilterReady) {
                            scope.onFilterReady();
                        }
                    }
                });

                scope.onDiseaseModalLoads = (options) => {
                    scope.resetDiseaseModal = options.reset;
                };

                function bindOnSelectItems(optionsName) {
                    return function (count) {
                        scope.filter[optionsName].selectedCount = count;
                    }
                }

                function bindOnExcludeItems(optionsName) {
                    return count => {
                        scope.filter[optionsName].excludedCount = count;
                    }
                }

                function getShowModalFunction(optionsName) {
                    return function () {
                        showSource(optionsName);
                    }
                }

                function showSource(optionsName) {
                    updateSource(optionsName, function () {
                        var sourceOptions = scope.filter[optionsName];

                        var selector = '#' + sourceOptions.modalId;

                        ModalHelper.showModal(selector, null, ModalHelper.optionsAssets.blockClosing);

                        ModalHelper.on(ModalHelper.events.hidden, selector, function onHide() {
                            if (scope.onModalChange) {
                                scope.onModalChange(optionsName, getModalData(sourceOptions));
                                updateAllModals(optionsName);
                            }

                            this_.invokeOnFilterChange(scope);
                            ModalHelper.off(ModalHelper.events.hidden, selector, onHide);
                        });
                    });
                }

                function updateSource(optionsName, callback) {
                    var sourceOptions = scope.filter[optionsName];
                    sourceOptions.loading = true;

                    this_.loadSource(scope, sourceOptions, function (source) {
                        var newOptions = sourceOptions.mapSource(source, sourceOptions.options);

                        sourceOptions.options = _.merge([], sourceOptions.options || [], newOptions);

                        for (var i = 0; i < newOptions.length; i++) {
                            sourceOptions.options[i].source = newOptions[i].source;
                        }

                        sourceOptions.partly = undefined;

                        sourceOptions.loading = false;

                        if (callback) {
                            callback();
                        }
                    });
                }

                function getModalData(sourceOptions) {
                    return sourceOptions.options.map(function (options) {
                        return options.source.filter(function (x) {
                            return x.selected;
                        });
                    });
                }

                function resetFilter() {
                    if (scope.resetDiseaseModal) {
                        scope.resetDiseaseModal();
                    }
                    
                    this_.setDefaultFilter(scope);
                    this_.invokeOnFilterChange(scope);
                }

                function onRegulatoryChange(newValue, oldValue) {
                    if (scope.onRegulatoryChange) {
                        scope.onRegulatoryChange(getChangedRegulatoryItemName(newValue, oldValue))
                    }

                    this_.invokeOnFilterChange(scope);
                }

                function onTimeframeChange() {
                    if (scope.onTimeframeChange) {
                        scope.onTimeframeChange(scope.timeframe)
                    }

                    this_.invokeOnFilterChange(scope);
                }

                function getChangedRegulatoryItemName(newValue, oldValue) {
                    var names = ['fasttrack', 'spa', 'orphan', 'breakthrough', 'rmat', 'qidp', 'isFirstIndication', 'isRareDisease'];
                    var returnNames = ['Fast Track', 'SPA', 'Orphan', 'Breakthrough', 'RMAT', 'QIDP', 'First Indication', 'Rare Disease'];

                    for (var i = 0; i < names.length; i++) {
                        if (newValue[names[i]] !== oldValue[names[i]]) {
                            return returnNames[i];
                        }
                    }
                }

                function applyFilter(filter) {
                    this_.applyFilter(scope, filter);
                }

                function getFilterWithNames() {
                    return this_.getFilter(scope, true);
                }

                function updateAllModals(ignore) {
                    for (var x in scope.filter) {
                        var filter = scope.filter[x];

                        if (x !== ignore && filter.options) {
                            var options = filter.options;

                            if (shouldBeUpdated(options[options.length - 1].source)) {
                                updateSource(x);
                            }
                        }
                    }
                }

                function shouldBeUpdated(items) {
                    return _.some(items, function (x) {
                        return x.selected || x.excluded;
                    });
                }
            },
            invokeOnFilterChange: function (scope) {
                if (scope.onFilterChange) {
                    scope.onFilterChange(this.getFilter(scope));
                }
            },
            getDateRangeOptions: function (scope, startDate, endDate) {
                return {
                    datePickerOptions: {
                        minDate: ConstantsSvc.Dates.start,
                        maxDate: ConstantsSvc.Dates.end
                    },
                    defaults: {
                        startDate: startDate || ConstantsSvc.Dates.start,
                        endDate: endDate || ConstantsSvc.Dates.end
                    },
                    onChange: function (startDate, endDate) {
                        scope.timeframe.startDate = startDate;
                        scope.timeframe.endDate = endDate;
                        scope.$apply();
                    },
                    onHide: function () {
                    }
                };
            },
            loadSource: function (scope, sourceOptions, callback) {
                var filter = this.getFilter(scope);

                sourceOptions.getSource(filter).then(function (source) {
                    if (callback) {
                        callback(source);
                    }
                });
            },
            applyFilter: function (scope, filter) {
                this.setDefaultFilter(scope);

                fillModal(scope.filter.diseases, filter.indications, null, true);
                fillModal(scope.filter.companies, filter.companies, {
                    value: filter.companyMode || this.defaultCompanyMode,
                    index: 0
                });
                fillModal(scope.filter.drugClasses, filter.drugClasses);
                fillModal(scope.filter.molecules, filter.molecules);
                fillModal(scope.filter.targets, filter.targets);
                fillModal(scope.filter.drugs, filter.drugs);
                fillModal(scope.filter.routes, filter.routes);

                fillExcludedItems(scope.filter, filter.excludedItems);

                scope.timeframe = {
                    startDate: Date.createDateWithoutTimezone(filter.startDate),
                    endDate: Date.createDateWithoutTimezone(filter.endDate)
                };

                scope.dateRangeOptions = this.getDateRangeOptions(scope, scope.timeframe.startDate, scope.timeframe.endDate);

                setRegulatory();

                this.invokeOnFilterChange(scope);

                function setRegulatory() {
                    Object.keys(scope.regulatory).forEach((regulatoryKey) => {
                        const newValue = filter[regulatoryKey.toLowerCase()];
                        scope.regulatory[regulatoryKey] = undefined === newValue ? null : newValue;
                    });
                }

                function fillModal(filterItem, source, modeData, isNotSingle) {
                    filterItem.options = getOptions(!isNotSingle, source);

                    if (modeData != null) {
                        filterItem.options[modeData.index].modeOptions = {value: modeData.value};
                    }

                    filterItem.partly = true;
                }

                function getOptions(single, source) {
                    var result = single
                        ? []
                        : [{source: []}, {source: []}]

                    result.push({source: mapSource(source)});

                    return result;
                }

                function mapSource(source) {
                    if (!source) {
                        return source;
                    }

                    return source.map(function (x) {
                        return _.merge(x, {selected: true});
                    });
                }
            },
            setDefaultFilter: function (scope) {
                var this_ = this;

                scope.filter = {
                    diseases: {
                        modalId: 'diseases-modal',
                        getSource: DiseaseService.getDiseases,
                        query: '',
                        mapSource: mapDiseases,
                        subLink: {
                            url: '/coverage-universe',
                            text: 'Coverage Universe'
                        }
                    },
                    companies: {
                        modalId: 'companies-modal',
                        getSource: CompanyService.getCompanies,
                        mapSource: getMapForSingleModal('companies', 'totalCount', getAdditionalOptionsForCompanies())
                    },
                    drugClasses: {
                        modalId: 'drug-classes-modal',
                        getSource: DrugClassesService.getDrugClasses,
                        mapSource: getMapForSingleModal('drugClasses', 'totalCount')
                    },
                    molecules: {
                        modalId: 'molecules-modal',
                        getSource: MoleculeService.getMolecules,
                        mapSource: getMapForSingleModal('molecules', 'totalCount')
                    },
                    targets: {
                        modalId: 'targets-modal',
                        getSource: TargetService.getTargets,
                        mapSource: getMapForSingleModal('targets', 'totalCount', {excludingIsAvailable: true})
                    },
                    drugs: {
                        modalId: 'drugs-modal',
                        getSource: DrugService.getDrugs,
                        mapSource: getMapForSingleModal('drugs', 'totalCount')
                    },
                    routes: {
                        modalId: 'routes-modal',
                        getSource: RouteService.getRoutes,
                        mapSource: getMapForSingleModal('routes', 'totalCount')
                    }
                };

                scope.timeframe = {
                    startDate: ConstantsSvc.Dates.start,
                    endDate: ConstantsSvc.Dates.end
                };

                scope.dateRangeOptions = this.getDateRangeOptions(scope);

                scope.regulatory = {
                    fasttrack: null,
                    spa: null,
                    orphan: null,
                    breakthrough: null,
                    rmat: null,
                    qidp: null,
                    isFirstIndication: null,
                    isPhaseIISkipped: null,
                    isPhaseIIISkipped: null,
                    isRareDisease: null
                };

                function mapDiseases(source, options) {
                    return [
                        createFilterOption(fillSource(getSource(options, 0), source.diseases), "Disease Groups", "Indications", {
                            totalCount: source.totalDiseaseCount,
                            searchHidden: true
                        }),

                        createFilterOption(fillSource(getSource(options, 1), source.subDiseases), "Sub-disease Groups", "Indications", {
                            searchHidden: true
                        }),

                        createFilterOption(fillSource(getSource(options, 2), source.indications), "Indications", "Programs", {
                            searchHidden: true
                        })
                    ];
                }

                function getMapForSingleModal(modalName, totalName, additionalOptions) {
                    return function (source, options) {
                        additionalOptions = _.merge(additionalOptions, {totalCount: source[totalName]});

                        var result = [
                            createFilterOption(fillSource(getSource(options, 0), source[modalName]), 'Name', null, additionalOptions)
                        ];

                        if (options && options[0].modeOptions) {
                            result[0].modeOptions.value = options[0].modeOptions.value;
                        }

                        return result;
                    }
                }

                function getSource(options, index) {
                    return options ? options[index].source : [];
                }

                function createFilterOption(data, label, subLabel, additionalOptions) {
                    var options = {
                        source: data,
                        label: label,
                        subLabel: subLabel,
                    }

                    return _.merge(options, additionalOptions);
                }

                function fillSource(oldSource, newSource) {
                    _.forEach(newSource, function (x) {
                        var item = _.find(oldSource, function (z) {
                            return z.id === x.id;
                        });

                        if (item) {
                            x.selected = item.selected;
                            x.subSelected = item.subSelected;
                            x.highlighted = item.highlighted;
                            x.excluded = item.excluded;
                        }
                    });

                    return newSource;
                }

                function getAdditionalOptionsForCompanies() {
                    return {
                        modeOptions: {
                            label: 'Company is:',
                            source: [
                                {label: 'Lead', value: CompanyModesEnum.leadOnly},
                                {label: 'Partner', value: CompanyModesEnum.partnersOnly},
                                {label: 'Either', value: CompanyModesEnum.all}
                            ],
                            value: this_.defaultCompanyMode,
                        },
                        onModeChange: onCompanyModeChange
                    }
                }

                function onCompanyModeChange() {
                    var options = scope.filter.companies.options;
                    options[0].loading = true;

                    this_.loadSource(scope, scope.filter.companies, function (source) {
                        var newOptions = _.merge([], options);

                        newOptions[0].source = fillSource(options[0].source, source.companies);
                        newOptions[0].totalCount = source.totalCount;

                        newOptions[0].loading = false;

                        scope.filter.companies.options = newOptions;
                    });
                }
            },
            getFilter: function (scope, includeNames) {
                var indicationsIndex = 2;
                var subDiseasesIndex = 1;
                var diseasesIndex = 0;

                var resultFilter = {
                    indications: getSelectedIds(scope.filter.diseases.options, indicationsIndex),
                    subDiseases: getSelectedIds(scope.filter.diseases.options, subDiseasesIndex),
                    diseases: getSelectedIds(scope.filter.diseases.options, diseasesIndex),
                    companies: getSelectedIds(scope.filter.companies.options),
                    drugclasses: getSelectedIds(scope.filter.drugClasses.options),
                    molecules: getSelectedIds(scope.filter.molecules.options),
                    targets: getSelectedIds(scope.filter.targets.options),
                    drugs: getSelectedIds(scope.filter.drugs.options),
                    routes: getSelectedIds(scope.filter.routes.options),
                    companyMode: getMode(scope.filter.companies.options, this.defaultCompanyMode),
                    excludedItems: getExcludedFilter(scope.filter, includeNames)
                };

                resultFilter = _.merge(resultFilter, mapRegulatory(scope.regulatory));

                resultFilter.startDate = moment(scope.timeframe.startDate).format(ConstantsSvc.Dates.queryFormat);
                resultFilter.endDate = moment(scope.timeframe.endDate).format(ConstantsSvc.Dates.queryFormat);

                return resultFilter;

                function getMode(options, defaultValue, index) {
                    index = index == null ? 0 : index;

                    return options && options[index].modeOptions ? options[index].modeOptions.value : defaultValue;
                }

                function getSelectedIds(options, index) {
                    if (options == null) {
                        return null;
                    }

                    var source = options[index == null ? 0 : index].source;

                    var selectedItems = _.filter(source, function (x) {
                        return x.selected;
                    });

                    if (selectedItems.length === 0) {
                        return null;
                    }

                    return selectedItems.map(x => mapItem(x, includeNames));
                }

                function mapRegulatory(regulatory) {
                    return _.pick(regulatory, Object.keys(scope.regulatory));
                }
            }
        }
    }

    function getExcludedFilter(filter, includeNames) {
        return {
            targets: getExcludedItem(filter, x => x.targets, includeNames)
        }
    }

    function getExcludedItem(filter, getItem, includeNames) {
        const options = getItem(filter).options;

        if (!options) {
            return null;
        }

        const source = options.last().source;

        const excludedItems = source ? source.filter(x => x.excluded) : [];

        return excludedItems.length
            ? excludedItems.map(x => mapItem(x, includeNames))
            : null;
    }

    function mapItem({id, name}, includeNames) {
        return includeNames
            ? {id, name}
            : {id};
    }

    function fillExcludedItems(filter, excludedItems = {}) {
        const {targets: {options: [targetOptions]}} = filter;

        applyExcludedSource(targetOptions, excludedItems.targets);
    }

    function applyExcludedSource(options, excludedItemList = []) {
        const itemsToPush = [];

        if (!options.source) {
            options.source = [];
        }

        _.forEach(excludedItemList, excludedItem => {
            const [item] = options.source.filter(x => x.id === excludedItem.id);

            if (item) {
                item.excluded = true;
            } else {
                itemsToPush.push(_.merge({excluded: true}, excludedItem));
            }
        });

        options.source.push(...itemsToPush);
    }
})();
